import React, { useState, useReducer, useEffect } from "react";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import {
  Button,
  Paper,
  Table,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Container,
  IconButton,
  Box,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import LabelModal from "../../components/LabelModal";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import openSocket from "../../services/socket-io";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_LABELS") {
    const labels = action.payload;
    const newLabels = [];

    labels.forEach((label) => {
      const labelIndex = state.findIndex((l) => l.id === label.id);
      if (labelIndex !== -1) {
        state[labelIndex] = label;
      } else {
        newLabels.push(label);
      }
    });

    return [...state, ...newLabels];
  }

  if (action.type === "UPDATE_LABEL") {
    const label = action.payload;
    const labelIndex = state.findIndex((l) => l.id === label.id);
    if (labelIndex !== -1) {
      state[labelIndex] = label;

      return [...state];
    } else {
      return [...state, label];
    }
  }

  if (action.type === "DELETE_LABEL") {
    const labelId = action.payload;
    const labelIndex = state.findIndex((l) => l.id === labelId);

    if (labelIndex !== -1) {
      state.splice(labelIndex, 1);
    }

    return [...state];
  }

  return state;
};

const Labels = () => {
  const classes = useStyles();
  const [labels, dispatch] = useReducer(reducer, []);
  const [labelOpenModal, setLabelOpenModal] = useState(false);
  const [labelId, setLabelId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/labels");
        dispatch({ type: "LOAD_LABELS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("label", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_LABEL", payload: data.label });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_LABEL", payload: data.labelId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenLabelModal = () => {
    setLabelId(null);
    setLabelOpenModal(true);
  };

  const handleEditLabel = (label) => {
    setLabelOpenModal(true);
    setLabelId(label.id);
  };

  const handleDeleteLabel = async (labelId) => {
    try {
      await api.delete(`labels/${labelId}`);
      toast.success(i18n.t("Label deleted successfully"));
    } catch (err) {
      toastError(err);
    }

    setLabelId(null);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <LabelModal
        open={labelOpenModal}
        labelId={labelId}
        onClose={() => setLabelOpenModal(false)}
      />
      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => {
          handleDeleteLabel(labelId);
        }}
      >
        {i18n.t("labels.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("labels.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenLabelModal}
          >
            {i18n.t("labels.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">{i18n.t("labels.table.no")}</TableCell>
              <TableCell align="center">
                {i18n.t("labels.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("labels.table.description")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("labels.table.color")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("labels.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {labels.map((label, index) => (
                <TableRow key={label.id}>
                  <TableCell align="left">
                    <Typography component="div">
                      <Box>{index + 1}</Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{label.name}</TableCell>
                  <TableCell align="center">{label.description}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: label.color,
                          width: 30,
                          height: 30,
                          alignSelf: "center",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditLabel(label)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setConfirmModalOpen(true);
                        setLabelId(label.id);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default Labels;
